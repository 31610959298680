html {
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: SpoqaHanSans;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  border: 0;
  margin: auto;
  max-width: 100%;
  background-color: #fff;
  flex: 1 1 0%;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#__next {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

textarea:focus, input:focus {
  outline: none;
}

.swiper-wrapper {
  display: flex;
}
.swiper-wrapper .swiper-slide {
  flex: 0 0 auto;
}

.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  padding: 4px 12px 7px;
  margin-top: 10px;
  background-color: rgba(51, 51, 51, 0.9);
}

.rc-slider-tooltip-hidden {
  display: none;
}

.rc-slider-tooltip-inner {
  min-width: 24px;
  font-size: 11px;
  height: auto;
  padding: 0;
  box-shadow: none;
  background: none;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
}

.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: -4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: rgba(51, 51, 51, 0.9);
}
.dn-important {
  display: none !important;
}
.disable-pull-to-refresh {
  overscroll-behavior: none;
}